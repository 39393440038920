
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "voluntary",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_API_URL,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("ข่าวสารและบทความ", []);
    });
    return {};
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
